<template>
<div :style="{height: height}">
  <div v-show="chartOption" ref="chartRef" :id="id" :class="className" :style="{height: height}"></div>
  <NoData v-show="!chartOption" />
</div>
</template>

<script setup>
import NoData from "@/components/NoData.vue"
import {
  defineEmits,
  defineProps,
  ref,
  watch,
  onActivated,
  onBeforeUnmount,
  onDeactivated,
  onMounted,
} from 'vue';
import * as echarts from 'echarts';
import { v4 as uid } from 'uuid';

// import resize from './hooks/use-resize';
import { chartExportImage } from './hooks/use-export-data';

const emits = defineEmits([]);
const props = defineProps({
  id: { type: String, default: uid() },
  className: { type: String, default: '' },
  height: { type: String, default: '500px' },
  theme: { type: String, default: 'light' },
  chartOption: { required: true }, // e chart 配置
  option: { type: Object, default: () => Object.create(null) }, // 非 e chart 配置
});

// const chart = ref(null);
let chart = null;
const chartRef = ref(null);

onMounted(() => {
  watch(() => props.chartOption, () => {
    updateChart();
  }, { deep: true, immediate: true });
});

// 初始化图表
function initChart() {
  chart = echarts.init(chartRef.value);
}

// 更新图表数据
function updateChart() {
  if (!chart) {
    initChart();
  }
  if (!props.chartOption) {
    return;
  }
  chart.setOption(props.chartOption, props.theme);
}

// 重置尺寸
function resize() {
  if (!chart) {
    return;
  }

  chart.resize();
}

onMounted(() => window.addEventListener('resize', resize));
onBeforeUnmount(() => window.removeEventListener('resize', resize));

/**
 * 导出图表图片
 * @param myChart：e chart 图表元素
 * @param opts：e chart 导出图片方法的配置项
 * @param download 直接下载图片
 * @param upload 上传图片至服务器
 * @param uploadDir 上传图片至服务器的目录
 */
async function exportImage({ myChart, opts, download, upload, uploadDir } = {}) {
  myChart = myChart || chart;
  if (!myChart) {
    return {};
  }

  return chartExportImage({ myChart, opts, download, upload, uploadDir });
}

function downloadImage(url, fileName) {
  const a = document.createElement('a');
  a.download = fileName || '统计图.png';
  a.style.display = 'none';
  a.href = url;
  document.body.appendChild(a);
  a.click();
  URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
}


defineExpose({
  chart,
  chartRef,
  exportImage,
});
</script>
