import { computed, watch } from "vue";
// import { uploadDocumentFile } from "@/api/data-analyse";
// import { cosUpload } from '@/views/PlanetG/public/uploadFile';

// base64转file
function dataURLtoFile(dataurl, filename) {
  let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while(n--){
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type:mime});
}

async function uploadOss(base64, filename, sn) {
  // const file = dataURLtoFile(base64, filename)
  //
  //   const name = `${file.name}.jpg`
  //   const data = await cosUpload(file, `diagram/${sn}/`, `${new Date().getTime()}/${name}`);
  //
  //   const urlData = [
  //     {
  //       name: `${file.name}.png`,
  //       url: data.url,
  //       size: file.size
  //     }
  //   ]
  //   console.log('上传成功',urlData);

    // uploadDocumentFile({
    //   url: JSON.stringify(urlData),
    //   parent_sn: sn,
    //   import_type: 5, //图表分析
    // })

}


export default function (chart, pixelRatio = 1) {
  const ImageUrl = computed(() => {
    if(!chart.value) return ''
    return chart.value.getDataURL({
      type: "png",
      pixelRatio,
      backgroundColor: "#fff",
    })
  })
  const exportImage = (name, sn) => {
    if (!chart.value) {
      return
    }
    const url = chart.value.getDataURL({
      type: "png",
      pixelRatio: 1.5, //放大两倍下载，之后压缩到同等大小展示。解决生成图片在移动端模糊问题
      backgroundColor: "#fff",
    }); //获取到的是一串base64信息
    // uploadOss(url, name, sn)
    const elink = document.createElement("a");
    elink.download = (name || "统计图") + '.png';
    elink.style.display = "none";
    elink.href = url;
    document.body.appendChild(elink);
    elink.click();
    URL.revokeObjectURL(elink.href); // 释放URL 对象
    document.body.removeChild(elink);
  }

  return { ImageUrl,  exportImage}
}


/**
 * 导出图表图片
 * @param myChart：e chart 图表元素
 * @param opts：e chart 导出图片方法的配置项
 * @param download 直接下载图片
 * @param upload 上传图片至服务器
 * @param uploadDir 上传图片至服务器的目录
 */
export async function chartExportImage({ myChart, opts, download, upload, uploadDir } = {}) {
  myChart = myChart || chart;
  if (!myChart) {
    return {};
  }

  opts = opts || {};
  opts.type = opts.type || 'png';
  opts.pixelRatio = opts.pixelRatio || 1.5; // 放大两倍下载，之后压缩到同等大小展示。解决生成图片在移动端模糊问题
  opts.backgroundColor = opts.backgroundColor || 'transparent';

  const base64 = myChart.getDataURL(opts); // 获取到的是一串 base64 信息
  const file = dataURLtoFile(base64, `${new Date().getTime()}_${Math.round(Math.random() * 1000)}.png`);

  let uploadRes;
  if (upload) {
    uploadRes = await cosUpload(file, uploadDir);
  }

  if (download) {
    downloadImage(base64, '', upload, uploadDir);
  }

  return { base64, ...(uploadRes || {}) };
}

function downloadImage(url, fileName) {
  const a = document.createElement('a');
  a.download = fileName || '图表.png';
  a.style.display = 'none';
  a.href = url;
  document.body.appendChild(a);
  a.click();
  URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
}
